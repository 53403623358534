import React from 'react';
import './HeroSVG.css';


const HeroSVG = () => (
    <div id="hero-text">
        <svg id="logo" width="523" height="91" viewBox="0 0 523 91" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M59.104 6.79999V74H52.096V42.992H10.048V74H2.944V6.79999H10.048V36.752H52.096V6.79999H59.104Z" stroke="black" strokeWidth="1" mask="url(#path-1-outside-1)"/>
            <path d="M79.5977 23.504H86.4137V74H79.5977V23.504ZM83.0537 12.464C81.6457 12.464 80.4617 11.984 79.5017 11.024C78.5417 10.064 78.0618 8.912 78.0618 7.568C78.0618 6.288 78.5417 5.168 79.5017 4.20799C80.4617 3.24799 81.6457 2.76799 83.0537 2.76799C84.4617 2.76799 85.6458 3.24799 86.6058 4.20799C87.5658 5.10399 88.0458 6.19199 88.0458 7.472C88.0458 8.88 87.5658 10.064 86.6058 11.024C85.6458 11.984 84.4617 12.464 83.0537 12.464Z" stroke="black" strokeWidth="1" mask="url(#path-1-outside-1)"/>
            <path d="M106.147 64.016C107.619 64.016 108.835 64.528 109.795 65.552C110.755 66.512 111.235 67.728 111.235 69.2C111.235 69.968 111.107 70.8 110.851 71.696C110.659 72.592 110.339 73.616 109.891 74.768L105.859 88.016H101.251L104.515 74.096C103.491 73.776 102.659 73.2 102.019 72.368C101.379 71.472 101.059 70.416 101.059 69.2C101.059 67.728 101.539 66.512 102.499 65.552C103.459 64.528 104.675 64.016 106.147 64.016Z" stroke="black" strokeWidth="1" mask="url(#path-1-outside-1)"/>
            <path d="M152.288 6.79999H159.392V74H152.288V6.79999Z" stroke="black" strokeWidth="1" mask="url(#path-1-outside-1)"/>
            <path d="M180.585 2.28799C182.057 2.28799 183.272 2.8 184.232 3.824C185.192 4.784 185.673 6 185.673 7.472C185.673 8.23999 185.544 9.07199 185.288 9.96799C185.096 10.8 184.809 11.792 184.424 12.944L180.296 26.192H175.689L179.049 12.368C177.961 12.048 177.096 11.472 176.456 10.64C175.816 9.744 175.497 8.688 175.497 7.472C175.497 5.936 175.976 4.688 176.936 3.72799C177.896 2.76799 179.113 2.28799 180.585 2.28799Z" stroke="black" strokeWidth="1" mask="url(#path-1-outside-1)"/>
            <path d="M263.136 23.12C269.472 23.12 274.432 24.944 278.016 28.592C281.664 32.24 283.488 37.616 283.488 44.72V74H276.672V45.392C276.672 40.144 275.392 36.144 272.832 33.392C270.336 30.64 266.784 29.264 262.176 29.264C256.928 29.264 252.8 30.896 249.792 34.16C246.784 37.36 245.28 41.808 245.28 47.504V74H238.464V45.392C238.464 40.144 237.184 36.144 234.624 33.392C232.128 30.64 228.544 29.264 223.872 29.264C218.688 29.264 214.56 30.896 211.488 34.16C208.48 37.36 206.976 41.808 206.976 47.504V74H200.16V23.504H206.688V32.72C208.48 29.648 210.976 27.28 214.176 25.616C217.376 23.952 221.056 23.12 225.216 23.12C229.44 23.12 233.088 24.016 236.16 25.808C239.296 27.6 241.632 30.256 243.168 33.776C245.024 30.448 247.68 27.856 251.136 26C254.656 24.08 258.656 23.12 263.136 23.12Z" stroke="black" strokeWidth="1" mask="url(#path-1-outside-1)"/>
            <path d="M370.682 56.048H333.242L325.178 74H317.786L348.506 6.79999H355.514L386.234 74H378.746L370.682 56.048ZM368.09 50.288L351.962 14.192L335.834 50.288H368.09Z" stroke="black" strokeWidth="1" mask="url(#path-1-outside-1)"/>
            <path d="M395.91 2.76799H402.726V74H395.91V2.76799Z" stroke="black" strokeWidth="1" mask="url(#path-1-outside-1)"/>
            <path d="M465.66 50.864H423.419C423.803 56.112 425.819 60.368 429.467 63.632C433.115 66.832 437.724 68.432 443.292 68.432C446.428 68.432 449.308 67.888 451.932 66.8C454.556 65.648 456.827 63.984 458.747 61.808L462.587 66.224C460.347 68.912 457.531 70.96 454.139 72.368C450.811 73.776 447.131 74.48 443.099 74.48C437.915 74.48 433.308 73.392 429.276 71.216C425.308 68.976 422.204 65.904 419.964 62C417.724 58.096 416.604 53.68 416.604 48.752C416.604 43.824 417.66 39.408 419.772 35.504C421.948 31.6 424.892 28.56 428.604 26.384C432.38 24.208 436.604 23.12 441.276 23.12C445.948 23.12 450.14 24.208 453.852 26.384C457.564 28.56 460.475 31.6 462.587 35.504C464.699 39.344 465.755 43.76 465.755 48.752L465.66 50.864ZM441.276 28.976C436.412 28.976 432.315 30.544 428.987 33.68C425.723 36.752 423.867 40.784 423.419 45.776H459.228C458.78 40.784 456.892 36.752 453.564 33.68C450.3 30.544 446.204 28.976 441.276 28.976Z" stroke="black" strokeWidth="1" mask="url(#path-1-outside-1)"/>
            <path d="M510.383 74L494.351 52.88L478.223 74H470.543L490.511 48.08L471.503 23.504H479.183L494.351 43.28L509.519 23.504H517.007L497.999 48.08L518.159 74H510.383Z" stroke="black" strokeWidth="1" mask="url(#path-1-outside-1)"/>
        </svg>
        <p>UX designer &amp; Fullstack developer</p>
    </div>
);

export default HeroSVG;