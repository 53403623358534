import React  from 'react';
import Hero from "../hero/Hero";
import Projects from "../projects/Projects";

const Home = () => {

  
  return (
    <>
      <Hero />
      <Projects />
    </>
  )
};

export default Home;